import {Sidebar} from "../utils/Sidebar";
import {
    Box,
    Button,
    DialogContent,
    DialogTitle,
    FormControl, FormLabel,
    IconButton, Input,
    Modal,
    ModalDialog, Select,
    Option, Grid, Chip, Stack, Sheet, Checkbox, Typography, ModalOverflow, Divider
} from "@mui/joy";
import {Header} from "../utils/Header";
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'moment/locale/it'
import {Add} from "@mui/icons-material";
import {useRef, useState} from "react";
import {
    calculateEaster,
    COLOR_ANDREA,
    COLOR_ANDREA_PENDING,
    COLOR_MIRKO, COLOR_MIRKO_PENDING,
    COLOR_PENDING,
    DB_PRENOTAZIONI, getHolidays, isValidEmail, isValidPhoneNumber, STATUS_CONFIRMED, STATUS_DELETED,
    STATUS_PENDING, STATUS_REJECTED,
    UUID
} from "../utils/General";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


export function Prenotazioni({data}) {

    const [filterAndrea, setFilterAndrea] = useState(true)
    const [filterMirko, setFilterMirko] = useState(true)

    const localizer = momentLocalizer(moment)
    const events = data.map(a => {
        const s = moment.unix(a.datetimestart)
        const e = moment.unix(a.datetimeend)
        if((a.barber==="Andrea" && filterAndrea) || (a.barber==="Mirko" && filterMirko)) {
            return {
                ...a,
                start: s.toDate(),
                end: e.toDate(),
                title: a.surname + " " + a.name,
                date: s.format("YYYY-MM-DD"),
                time: s.format("HH:mm"),
                duration: e.diff(s) / 60000,
            }
        }
    })
        .sort((a, b) => a.barber.localeCompare(b.barber))
        .concat(getHolidays().map(h => {
            const start = moment(h + "-" + moment().format("YYYY"), "MM-DD-YYYY").startOf("day")
            const end = start.clone().endOf("day")
            return {start: start.toDate(), end: end.toDate(), title: "Festività"}
        }))

    const eventStyleGetter = (event, start, end, isSelected) => {
        let backgroundColor
        switch (event.barber) {
            case "Andrea" :
                backgroundColor = event.status !== STATUS_PENDING ? COLOR_ANDREA : COLOR_ANDREA_PENDING
                break
            case "Mirko" :
                backgroundColor = event.status !== STATUS_PENDING ? COLOR_MIRKO : COLOR_MIRKO_PENDING
                break
            default :
                backgroundColor = COLOR_PENDING
                break
        }
        let style = {
            backgroundColor: backgroundColor,
            borderRadius: '5px',
            opacity: 1,
            color: 'black',
            border: '0px',
            display: 'block',
            boxShadow: "0 0 10px rgba(0,0,0,0.2)",
        };
        return {
            style: style
        };
    }

    const [loading, setLoading] = useState(false)
    const [modalAdd, setModalAdd] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)

    const prenotazioneInitialData = {
        name: "",
        surname: "",
        email: "",
        phone: "",
        date: null,
        time: null,
        duration: 30,
        barber: null,
        ripetiogni: 0,
        ripetiperquanto: 1
    }
    const [newPrenotazione, setNewPrenotazione] = useState(prenotazioneInitialData)

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: "row",
            justifyContent: "start",
            minHeight: "100vh",
            minWidth: "100%",
            maxWidth: "100%"
        }}>
            <Header/>
            <Sidebar/>
            <Box sx={{
                width: {xs:"100vw", lg:"calc(100vw - 175px)"},
                height: "calc(100vh - 4em)",
                ml: {xs:"0", lg:"100px"},
                mt: {xs:"60px", lg:0},
            }}>
                <Stack direction="row" sx={{justifyContent:"space-between", alignItems:"center", width:"100%", px:3, pt:3}}>
                    <Typography level="h2">Prenotazioni</Typography>
                    <Stack direction="row" sx={{justifyContent:"end"}} spacing={2}>
                        <Checkbox label="Andrea" size="sm"
                                  checked={filterAndrea}
                                  onClick={() => setFilterAndrea(!filterAndrea)}
                        />
                        <Checkbox label="Mirko" size="sm"
                                  checked={filterMirko}
                                  onClick={() => setFilterMirko(!filterMirko)}
                        />
                    </Stack>
                </Stack>
                <Box sx={{width: "100%", height: "100%", p: 3}}>
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        defaultView="week"
                        eventPropGetter={(eventStyleGetter)}
                        selectable={true}
                        min={new Date(2020, 1, 0, 6, 0, 0)} max={new Date(2020, 1, 0, 21, 0, 0)}
                        onSelectEvent={(e) => {
                            if(e.title !== "Festività") setSelectedEvent(e)
                        }}
                        onSelectSlot={(e) => {
                            const date = moment(e.start).format("YYYY-MM-DD")
                            const time = moment(e.start).format("HH:mm")
                            setNewPrenotazione({
                                ...newPrenotazione,
                                date: date,
                                time: time
                            })
                            setModalAdd(true)
                        }}
                    />
                    <IconButton variant="solid" color="primary"
                                sx={{position: "fixed", bottom: 0, right: 0, zIndex: 10, m: 3, borderRadius: 50}}
                                onClick={() => {
                                    setModalAdd(true)
                                }}
                    >
                        <Add/>
                    </IconButton>
                </Box>
            </Box>


            {/* MODAL ADD */}
            <Modal open={modalAdd} onClose={() => {
                setModalAdd(false)
                setNewPrenotazione(prenotazioneInitialData)
            }}>
                <ModalDialog>
                    <DialogTitle>Nuova prenotazione</DialogTitle>
                    <DialogContent>Completa i campi per inserire una nuova prenotazione.</DialogContent>
                    <Grid container spacing={2}>
                        <Grid md={6}>
                            <FormControl>
                                <FormLabel>Barbiere</FormLabel>
                                <Select placeholder="Scegli…"
                                        value={newPrenotazione.barber}
                                        onChange={(e, newValue) => setNewPrenotazione({
                                            ...newPrenotazione,
                                            barber: newValue
                                        })}
                                >
                                    <Option value="Andrea">Andrea</Option>
                                    <Option value="Mirko">Mirko</Option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid md={6}>
                            <FormControl>
                                <FormLabel>Data appuntamento</FormLabel>
                                <Input type="date"
                                       value={newPrenotazione.date}
                                       onChange={e => setNewPrenotazione({...newPrenotazione, date: e.target.value})}
                                       error={moment(newPrenotazione.date + " 23:59", "YYYY-MM-DD HH:mm").isBefore(moment())}
                                />
                            </FormControl>
                        </Grid>
                        <Grid md={6}>
                            <FormControl>
                                <FormLabel>Ora appuntamento</FormLabel>
                                <Input type="time"
                                       value={newPrenotazione.time}
                                       onChange={e => setNewPrenotazione({...newPrenotazione, time: e.target.value})}
                                       slotProps={{
                                           input: {
                                               step: 1800,
                                           },
                                       }}
                                       list="timelist"
                                />
                            </FormControl>
                        </Grid>
                        <Grid md={6}>
                            <FormControl>
                                <FormLabel>Durata appuntamento</FormLabel>
                                <Select placeholder="Scegli…"
                                        value={newPrenotazione.duration}
                                        onChange={(e, newValue) => setNewPrenotazione({
                                            ...newPrenotazione,
                                            duration: newValue
                                        })}
                                >
                                    <Option value={30}>30 min</Option>
                                    <Option value={60}>60 min</Option>
                                    <Option value={90}>90 min</Option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid md={6}>
                            <FormControl>
                                <FormLabel>Nome cliente</FormLabel>
                                <Input type="text" placeholder="Mario"
                                       value={newPrenotazione.name}
                                       onChange={e => setNewPrenotazione({...newPrenotazione, name: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid md={6}>
                            <FormControl>
                                <FormLabel>Cognome cliente</FormLabel>
                                <Input type="text" placeholder="Rossi"
                                       value={newPrenotazione.surname}
                                       onChange={e => setNewPrenotazione({...newPrenotazione, surname: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid md={6}>
                            <FormControl>
                                <FormLabel>Telefono cliente</FormLabel>
                                <Input type="tel" placeholder="333 000 0000"
                                       value={newPrenotazione.phone}
                                       onChange={e => setNewPrenotazione({...newPrenotazione, phone: e.target.value})}
                                       error={!isValidPhoneNumber(newPrenotazione.phone)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid md={6}>
                            <FormControl>
                                <FormLabel>Email cliente</FormLabel>
                                <Input type="email" placeholder="nome@email.com"
                                       value={newPrenotazione.email}
                                       onChange={e => setNewPrenotazione({...newPrenotazione, email: e.target.value})}
                                       error={!isValidEmail(newPrenotazione.email)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid md={6}>
                            <FormControl>
                                <FormLabel>Ripeti ogni</FormLabel>
                                <Select placeholder="Scegli…"
                                        value={newPrenotazione.ripetiogni}
                                        onChange={(e, newValue) => setNewPrenotazione({
                                            ...newPrenotazione,
                                            ripetiogni: newValue
                                        })}
                                >
                                    <Option value={0}>Evento singolo</Option>
                                    <Option value={1}>1 settimana</Option>
                                    <Option value={2}>2 settimane</Option>
                                    <Option value={3}>3 settimane</Option>
                                    <Option value={4}>4 settimane</Option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid md={6}>
                            <FormControl>
                                <FormLabel>Per quante volte</FormLabel>
                                <Input type="number"
                                       value={newPrenotazione.ripetiogni===0 ? "" : newPrenotazione.ripetiperquanto}
                                       onChange={e => setNewPrenotazione({...newPrenotazione, ripetiperquanto: Number(e.target.value)})}
                                       disabled={newPrenotazione.ripetiogni === 0}
                                       slotProps={{
                                           input: {
                                               min: 1,
                                               max: 100,
                                           },
                                       }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Button
                        disabled={newPrenotazione.name === "" || newPrenotazione.surname === "" || newPrenotazione.email === "" ||
                            newPrenotazione.phone === "" || newPrenotazione.barber === "" || newPrenotazione.date === null ||
                            newPrenotazione.time === null || newPrenotazione.duration === null || loading}
                        onClick={() => addPrenotazione().then(() => {
                            setModalAdd(false)
                            setLoading(false)
                        })}
                    >
                        Aggiungi
                    </Button>
                </ModalDialog>
            </Modal>

            {/* MODAL SELECTED EVENT */}
            <Modal open={Boolean(selectedEvent)} onClose={() => setSelectedEvent(null)}>
                <ModalDialog>
                    {
                        selectedEvent !== null ?
                            <>
                                <DialogTitle>
                                    <Chip
                                        variant="solid"
                                        color={
                                            selectedEvent.status===STATUS_CONFIRMED ?
                                                "success"
                                                :
                                                selectedEvent.status===STATUS_PENDING ?
                                                    "neutral"
                                                    :
                                                    "danger"
                                        }
                                    >
                                        {
                                            selectedEvent.status===STATUS_CONFIRMED ?
                                                "Confermato"
                                                :
                                                selectedEvent.status===STATUS_PENDING ?
                                                    "In attesa di conferma"
                                                    :
                                                    selectedEvent.status===STATUS_REJECTED ?
                                                        "Rifiutato"
                                                        :
                                                        "Eliminato"
                                        }
                                    </Chip>
                                    {selectedEvent.surname} {selectedEvent.name}
                                </DialogTitle>
                                <DialogContent>{moment(selectedEvent.start).format("DD/MM/YYYY HH:mm")} - {moment(selectedEvent.end).format("HH:mm")}</DialogContent>
                                <Grid container spacing={2}>
                                    <Grid md={6}>
                                        <FormControl>
                                            <FormLabel>Barbiere</FormLabel>
                                            <Select placeholder="Scegli…"
                                                    value={selectedEvent.barber}
                                                    onChange={(e, newValue) => setSelectedEvent({
                                                        ...selectedEvent,
                                                        barber: newValue
                                                    })}
                                            >
                                                <Option value="Andrea">Andrea</Option>
                                                <Option value="Mirko">Mirko</Option>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid md={6}>
                                        <FormControl>
                                            <FormLabel>Data appuntamento</FormLabel>
                                            <Input type="date"
                                                   value={selectedEvent.date}
                                                   onChange={e => setSelectedEvent({
                                                       ...selectedEvent,
                                                       date: e.target.value
                                                   })}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid md={6}>
                                        <FormControl>
                                            <FormLabel>Ora appuntamento</FormLabel>
                                            <Input type="time"
                                                   value={selectedEvent.time}
                                                   onChange={e => setSelectedEvent({
                                                       ...selectedEvent,
                                                       time: e.target.value
                                                   })}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid md={6}>
                                        <FormControl>
                                            <FormLabel>Durata appuntamento</FormLabel>
                                            <Select placeholder="Scegli…"
                                                    value={selectedEvent.duration}
                                                    onChange={(e, newValue) => setSelectedEvent({
                                                        ...selectedEvent,
                                                        duration: newValue
                                                    })}
                                            >
                                                <Option value={30}>30 min</Option>
                                                <Option value={60}>60 min</Option>
                                                <Option value={90}>90 min</Option>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid md={6}>
                                        <FormControl>
                                            <FormLabel>Nome cliente</FormLabel>
                                            <Input type="text" placeholder="Mario"
                                                   value={selectedEvent.name}
                                                   onChange={e => setSelectedEvent({
                                                       ...selectedEvent,
                                                       name: e.target.value
                                                   })}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid md={6}>
                                        <FormControl>
                                            <FormLabel>Cognome cliente</FormLabel>
                                            <Input type="text" placeholder="Rossi"
                                                   value={selectedEvent.surname}
                                                   onChange={e => setSelectedEvent({
                                                       ...selectedEvent,
                                                       surname: e.target.value
                                                   })}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid md={6}>
                                        <FormControl>
                                            <FormLabel>Telefono cliente</FormLabel>
                                            <Input type="tel" placeholder="333 000 0000"
                                                   value={selectedEvent.phone}
                                                   onChange={e => setSelectedEvent({
                                                       ...selectedEvent,
                                                       phone: e.target.value
                                                   })}
                                                   error={!isValidPhoneNumber(selectedEvent.phone)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid md={6}>
                                        <FormControl>
                                            <FormLabel>Email cliente</FormLabel>
                                            <Input type="email" placeholder="nome@email.com"
                                                   value={selectedEvent.email}
                                                   onChange={e => setSelectedEvent({
                                                       ...selectedEvent,
                                                       email: e.target.value
                                                   })}
                                                   error={!isValidEmail(selectedEvent.email)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid md={12}>
                                        <Stack direction="row" spacing={2}>
                                            <Button variant="outlined" color="danger" fullWidth
                                                    onClick={() => {
                                                        if (window.confirm("Eliminare la prenotazione?")) {
                                                            deletePrenotazione().then(() => {
                                                                setSelectedEvent(null)
                                                                setLoading(false)
                                                            })
                                                        }
                                                    }}
                                            >
                                                Annulla appuntamento
                                            </Button>
                                            {
                                                selectedEvent.status===STATUS_PENDING ?
                                                    <Button fullWidth
                                                            color="success"
                                                            disabled={selectedEvent.name === "" || selectedEvent.surname === "" || selectedEvent.email === "" ||
                                                                selectedEvent.phone === "" || selectedEvent.barber === "" || selectedEvent.date === null ||
                                                                selectedEvent.time === null || selectedEvent.duration === null || loading}
                                                            onClick={() => confirmPrenotazione().then(() => {
                                                                setSelectedEvent(null)
                                                                setLoading(false)
                                                            })}
                                                    >
                                                        Conferma appuntamento
                                                    </Button>
                                                    :
                                                    <Button fullWidth
                                                            disabled={selectedEvent.name === "" || selectedEvent.surname === "" || selectedEvent.email === "" ||
                                                                selectedEvent.phone === "" || selectedEvent.barber === "" || selectedEvent.date === null ||
                                                                selectedEvent.time === null || selectedEvent.duration === null || loading}
                                                            onClick={() => updatePrenotazione().then(() => {
                                                                setSelectedEvent(null)
                                                                setLoading(false)
                                                            })}
                                                    >
                                                        Modifica
                                                    </Button>
                                            }
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Divider inset="context" />
                                <Typography level="body-sm" textAlign="end">
                                    Codice prenotazione: <span className="span-as-pre">{selectedEvent.id}</span>
                                </Typography>
                            </>
                            : null
                    }
                </ModalDialog>
            </Modal>
        </Box>
    )

    async function addPrenotazione() {
        setLoading(true)

        if(newPrenotazione.ripetiogni > 0) {
            for (let i = 0; i < newPrenotazione.ripetiperquanto; i++) {
                const id = UUID()
                const datetimestart = moment(String(newPrenotazione.date + " " + newPrenotazione.time), "YYYY-MM-DD HH:mm").add(i*newPrenotazione.ripetiogni, "weeks")
                const datetimeend = datetimestart.clone().add(newPrenotazione.duration, "minutes")

                await firebase.firestore().collection(DB_PRENOTAZIONI).doc(id).set({
                    id: id,
                    name: newPrenotazione.name,
                    surname: newPrenotazione.surname,
                    email: newPrenotazione.email,
                    phone: newPrenotazione.phone,
                    datetimestart: datetimestart.unix(),
                    datetimeend: datetimeend.unix(),
                    barber: newPrenotazione.barber,
                    status: STATUS_CONFIRMED,
                    sendMessages: false
                })
            }
        } else {
            const id = UUID()
            const datetimestart = moment(String(newPrenotazione.date + " " + newPrenotazione.time), "YYYY-MM-DD HH:mm")
            const datetimeend = datetimestart.clone().add(newPrenotazione.duration, "minutes")

            await firebase.firestore().collection(DB_PRENOTAZIONI).doc(id).set({
                id: id,
                name: newPrenotazione.name,
                surname: newPrenotazione.surname,
                email: newPrenotazione.email,
                phone: newPrenotazione.phone,
                datetimestart: datetimestart.unix(),
                datetimeend: datetimeend.unix(),
                barber: newPrenotazione.barber,
                status: STATUS_CONFIRMED,
            })
        }
    }

    async function deletePrenotazione() {
        setLoading(true)

        await firebase.firestore().collection(DB_PRENOTAZIONI).doc(selectedEvent.id).update({
            status: STATUS_DELETED
        })
    }

    async function updatePrenotazione() {
        setLoading(true)

        const datetimestart = moment(String(selectedEvent.date + " " + selectedEvent.time), "YYYY-MM-DD HH:mm")
        const datetimeend = datetimestart.clone().add(selectedEvent.duration, "minutes")

        await firebase.firestore().collection(DB_PRENOTAZIONI).doc(selectedEvent.id).update({
            name: selectedEvent.name,
            surname: selectedEvent.surname,
            email: selectedEvent.email,
            phone: selectedEvent.phone,
            datetimestart: datetimestart.unix(),
            datetimeend: datetimeend.unix(),
            barber: selectedEvent.barber,
        })
    }
    async function confirmPrenotazione() {
        setLoading(true)

        const datetimestart = moment(String(selectedEvent.date + " " + selectedEvent.time), "YYYY-MM-DD HH:mm")
        const datetimeend = datetimestart.clone().add(selectedEvent.duration, "minutes")

        await firebase.firestore().collection(DB_PRENOTAZIONI).doc(selectedEvent.id).update({
            name: selectedEvent.name,
            surname: selectedEvent.surname,
            email: selectedEvent.email,
            phone: selectedEvent.phone,
            datetimestart: datetimestart.unix(),
            datetimeend: datetimeend.unix(),
            barber: selectedEvent.barber,
            status: STATUS_CONFIRMED
        })
    }
}