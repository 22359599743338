import {createContext, useEffect, useState} from "react";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth'


export const AuthContext = createContext()

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState(null)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                if(user.email === "danieledigregorio8@gmail.com") {
                    setCurrentUser(user)
                } else {
                    setCurrentUser(null)
                }
            } else {
                setCurrentUser(null)
            }
        })
    }, [])

    return (
        <AuthContext.Provider value={{currentUser}}>
            {children}
        </AuthContext.Provider>
    )
}