import './App.css';
import firebase from "firebase/compat/app";
import {Router} from "./utils/Router";
import {CssVarsProvider, useColorScheme} from '@mui/joy/styles';
import {Box} from "@mui/joy";
import {AuthProvider} from "./utils/AuthProvider"
import {DarkMode, LightMode} from "@mui/icons-material";
import {useEffect, useState} from "react";
import { getAnalytics } from "firebase/analytics";

if (!firebase.apps.length) {
    const app = firebase.initializeApp({
        apiKey: "AIzaSyBEc-veNw_ejWU2P-6q8D1osinxhcMi6Aw",
        authDomain: "scalp-squad.firebaseapp.com",
        projectId: "scalp-squad",
        storageBucket: "scalp-squad.appspot.com",
        messagingSenderId: "467265545657",
        appId: "1:467265545657:web:9fa5da9538214f86cbc268",
        measurementId: "G-5N88BJ22PH"
    })
    getAnalytics(app);
}

function App() {
    return (
        <div>
            <AuthProvider>
                <CssVarsProvider defaultMode="light">
                    <Box sx={{
                        minHeight: '100vh',
                        maxWidth: '100vw',
                        backgroundColor: "var(--joy-palette-background-body)",
                    }}
                    >
                        {/*<ModeToggle/>*/}
                        <Router />
                    </Box>
                </CssVarsProvider>
            </AuthProvider>
        </div>
    );
}

function ModeToggle() {
    const {mode, setMode} = useColorScheme()
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        setMounted(true)
    }, [])
    if(!mounted) return null

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                right: 0,
                p: 2,
            }}
        >
            <div
                onClick={() => {
                    setMode(mode==='light' ? 'dark' : 'light')
                }}
            >
                {
                    mode==='light' ?
                        <DarkMode
                            sx={{cursor:'pointer'}}
                        />
                        :
                        <LightMode
                            sx={{
                                cursor:'pointer',
                                color:'white',
                            }}
                        />
                }
            </div>
        </Box>
    )
}


export default App;
