import {Sidebar} from "../utils/Sidebar";
import {Box, Card, CircularProgress, Container, Grid, Stack, Typography} from "@mui/joy";
import {Header} from "../utils/Header";
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'moment/locale/it'
import {useEffect, useState} from "react";
import firebase from "firebase/compat/app";
import {DB_PRENOTAZIONI, FUNCTION_GET_STATS, STATUS_CONFIRMED, STATUS_DELETED, STATUS_REJECTED} from "../utils/General";
import moment from "moment";
import {getFunctions, httpsCallable} from "firebase/functions";


export function Statistiche() {

    const [loading, setLoading] = useState(null)
    const [stats, setStats] = useState(null)
    useEffect(() => {
        setLoading(true)
        const functions = getFunctions();
        const callablefunction = httpsCallable(functions, FUNCTION_GET_STATS);
        callablefunction()
            .then((res) => {
                switch (res.data.code) {
                    case 200:
                        setStats(res.data.stats)
                        setLoading(false)
                        break
                    case 404:
                        setLoading(false)
                        break
                    default:
                        setLoading(false)
                        break
                }
            })
            .catch((err) => {
                setLoading(false)
            })
    }, [])

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: "row",
            justifyContent: "start",
            minHeight: "100vh",
            minWidth: "100%",
            maxWidth: "100%"
        }}>
            <Header/>
            <Sidebar/>
            <Box sx={{
                width: {xs:"100vw", lg:"calc(100vw - 175px)"},
                height: "calc(100vh - 4em)",
                ml: {xs:"0", lg:"100px"},
                mt: {xs:"60px", lg:0},
            }}>
                <Stack direction="row" sx={{justifyContent:"space-between", alignItems:"center", width:"100%", px:3, pt:3}}>
                    <Typography level="h2">Statistiche</Typography>
                </Stack>
                <Container sx={{width:"100%", height:"100%", display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center"}}>
                    {
                        loading ?
                            <CircularProgress />
                            :
                            stats ?
                                <Grid container spacing={3}>
                                    {CardStats("Totale prenotazioni", stats.totalAppointments)}
                                    {CardStats("Prenotazioni confermate", stats.confirmedAppointments)}
                                    {CardStats("Prenotazioni eliminate", stats.deletedAppointments)}
                                    {CardStats("Clienti unici", stats.uniqueClients)}
                                    {CardStats("Tagli effettuati da Andrea", stats.pastConfirmedAppointmentsAndrea)}
                                    {CardStats("Tagli effettuati da Mirko", stats.pastConfirmedAppointmentsMirko)}
                                    {CardStats("Prenotazioni future", stats.upcomingAppointments)}
                                    {CardStats("Clienti unici Andrea", stats.confirmedClientsAndrea)}
                                    {CardStats("Clienti unici Mirko", stats.confirmedClientsMirko)}
                                </Grid>
                                :
                                <Typography>Si è verificato un errore</Typography>
                    }
                </Container>
            </Box>
        </Box>
    )
}

export function CardStats(
    title, value, desc
) {
    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card variant="solid" color="primary" invertedColors className="hovercursor" sx={{textAlign:"center"}} fullWidth>
                <Typography level="body">{title}</Typography>
                {
                    value === null ?
                        <CircularProgress/>
                        :
                        <Typography level="h1">{value}</Typography>
                }
                <Typography level="body-2" sx={{fontSize:"0.75em", opacity:"0.75", textTransform:"uppercase"}}>{desc}</Typography>
            </Card>
        </Grid>
    )
}