import {customAlphabet} from 'nanoid'
import moment from "moment";


export const DB_PRENOTAZIONI = "bookings"
export const DB_TURNI = "shifts"
export const FUNCTION_SLOT_DISPONIBILI = "getSlotDisponibili"
export const FUNCTION_PRENOTA_SLOT = "prenotaSlot"
export const FUNCTION_ANNULLA_PRENOTAZIONE = "annullaPrenotazione"
export const FUNCTION_MODIFICA_SLOT = "modificaSlot"
export const FUNCTION_GET_PRENOTAZIONE = "getPrenotazione"
export const FUNCTION_GET_STATS = "getStats"
export const STATUS_PENDING = "PENDING"
export const STATUS_CONFIRMED = "CONFIRMED"
export const STATUS_REJECTED = "REJECTED"
export const STATUS_DELETED = "DELETED"
export const COLOR_ANDREA = "#32a852"
export const COLOR_ANDREA_PENDING = "rgba(50,168,82,0.5)"
export const COLOR_MIRKO = "#3294a8"
export const COLOR_MIRKO_PENDING = "rgba(50,148,168,0.5)"
export const COLOR_PENDING = "#818181"
export const LOGO = "https://www.scalpsquadbarberia.it/wp-content/uploads/2024/06/13.png"
export const LOGO_SCRITTA = "https://www.scalpsquadbarberia.it/wp-content/uploads/2024/06/9.png"

export function UUID() {
    const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 10)
    return nanoid()
}

export function isValidPhoneNumber(phoneNumber) {
    const phoneRegex = /^(\+?\d{1,3})?\s?\d{6,14}$/
    return phoneRegex.test(phoneNumber);
}

export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function calculateEaster(year) {
    const f = Math.floor,
        G = year % 19,
        C = f(year / 100),
        H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
        I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
        J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
        L = I - J,
        month = 3 + f((L + 40) / 44),
        day = L + 28 - 31 * f(month / 4);
    return moment([year, month - 1, day]);
}

export function getHolidays() {
    // Calcola le date di Pasqua e Pasquetta per l'anno corrente
    const easter = calculateEaster(moment().format("YYYY"));
    const easterMonday = easter.clone().add(1, 'day');

    // Aggiungi le festività mobili al set di festività
    const holidays = [
        '01-01', // Capodanno
        '01-06', // Epifania
        '04-25', // Festa della Liberazione
        '05-01', // Festa del Lavoro
        '06-02', // Festa della Repubblica
        '06-24', // San Giovanni Battista
        '08-15', // Ferragosto
        '11-01', // Ognissanti
        '12-08', // Immacolata Concezione
        '12-25', // Natale
        '12-26'  // Santo Stefano
    ];
    const holidayDates = new Set(holidays);
    holidayDates.add(easter.format('MM-DD'));
    holidayDates.add(easterMonday.format('MM-DD'));

    return Array.from(holidayDates)
}