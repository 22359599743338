import {Sidebar} from "../utils/Sidebar";
import {Box, Grid, Stack, Typography} from "@mui/joy";
import {Header} from "../utils/Header";
import {useEffect, useState} from "react";
import moment from "moment";
import {CardStats} from "./Statistiche";
import {LOGO_SCRITTA} from "../utils/General";


export function Home({prenotazioni}) {

    const [stats, setStats] = useState({
        prenotazionioggi: null,
        prenotazionidomani: null
    })
    useEffect(() => {
        let prenotazionioggi = 0
        let prenotazionidomani = 0
        prenotazioni.map(a => {
            const date = moment.unix(a.datetimestart)
            const startofday = moment().startOf("day")
            const endofday = moment().endOf("day")
            if(date.isAfter(startofday) && date.isBefore(endofday)) prenotazionioggi++
            else if(date.isAfter(startofday.add(1, "day")) && date.isBefore(endofday.add(1, "day"))) prenotazionidomani++
        })

        setStats({
            prenotazionioggi: prenotazionioggi,
            prenotazionidomani: prenotazionidomani,
        })
    }, []);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: "row",
            justifyContent: "start",
            minHeight: "100vh",
            minWidth: "100%",
            maxWidth: "100%"
        }}>
            <Header />
            <Sidebar />

            <Box sx={{
                width: {xs:"100vw", lg:"calc(100vw - 175px)"},
                height: "calc(100vh - 4em)",
                ml: {xs:"0", lg:"100px"},
                mt: {xs:"60px", lg:0},
            }}>
                <Stack direction="row" sx={{justifyContent:"space-between", alignItems:"center", width:"100%", px:3, pt:3}}>
                    <Typography level="h2">Home</Typography>
                </Stack>
                <Box sx={{width:"100%", height:"100%", display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center"}}>
                    <img
                        src={LOGO_SCRITTA}
                        alt="Logo"
                        style={{filter:"invert(1)", height:"7rem", marginBottom:"4rem"}}
                        width="auto"
                    />
                    <Grid container spacing={3} sx={{width:"100%", justifyContent:"center"}}>
                        {CardStats("Prenotazioni di oggi", stats.prenotazionioggi, moment().format("DD/MM/YYYY"))}
                        {CardStats("Prenotazioni di domani", stats.prenotazionidomani, moment().add(1, "day").format("DD/MM/YYYY"))}
                    </Grid>
                </Box>
            </Box>
            {
                /*
                    <Box sx={{width: "83.5vw", height: "calc(100vh - 3em)", ml: "13vw", p:5, display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Box>
                            <Button onClick={() => {
                                getSlotLiberi(30, "Andrea")
                            }}>
                                FETCH SLOT LIBERI
                            </Button>
                            {
                                result !== null ?
                                    <pre>{JSON.stringify(result, null, 2)}</pre>
                                    :
                                    null
                            }
                        </Box>
                    </Box>
                 */
            }
        </Box>
    )
}