import {
    Box,
    Button,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    IconButton,
    Input,
    ListSubheader,
    Modal,
    ModalDialog,
    Sheet,
    Stack,
    Typography
} from "@mui/joy";
import {Event, Home, Key, Logout, Schedule, TrendingUp} from "@mui/icons-material";
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import firebase from "firebase/compat/app";
import {AuthContext} from "./AuthProvider";


export function Sidebar() {


    const pathname = useLocation().pathname
    const {currentUser} = useContext(AuthContext)
    const [modalChangePsw, setModalChangePsw] = useState(false)
    const [tab, setTab] = useState(0)
    const [oldPassword, setOldPassword] = useState("")
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [loading, setLoading] = useState(false)

    return (
        <>
            <Box
                className="SecondSidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    maxHeight: '100vh',
                    bgcolor: 'background.body',
                    opacity: 'calc(var(--SideNavigation-slideIn, 0) - 0.2)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />
            <Sheet
                className="SecondSidebar"
                sx={{
                    position: {
                        xs: 'fixed',
                        lg: 'fixed', //'sticky',
                    },
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
                        lg: 'none',
                    },
                    borderRight: '1px solid',
                    borderColor: 'divider',
                    transition: 'transform 0.4s',
                    zIndex: 9999,
                    height: '95dvh',
                    top: 0,
                    p: 2,
                    py: 3,
                    flexShrink: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Stack direction="column" spacing={2}>
                    <ListSubheader role="presentation" sx={{
                        color: 'text.primary',
                        display: {
                            sx: "block",
                            lg: "none"
                        },
                        width:"100%"
                    }}>
                        CONTROL PANEL
                    </ListSubheader>


                    {/* ADD PAGES HERE */}
                    <ItemMenu label="Dashboard" icon={<Home />} link="/" />
                    <ItemMenu label="Prenotazioni" icon={<Event />} link="/prenotazioni" />
                    <ItemMenu label="Turni" icon={<Schedule />} link="/turni" />
                    <ItemMenu label="Statistiche" icon={<TrendingUp />} link="/statistiche" />


                </Stack>
                <Box sx={{ pl: {xs:0, lg:1}, mt: 'auto' }}>
                    <Typography level="body2" fontSize="xs" textAlign="center" sx={{mb: 1, display:{xs:"block", lg:"none"}}}>{currentUser?.email}</Typography>
                    <Stack spacing={2} direction={{xs: "row", lg: "column"}} sx={{justifyContent:"center"}}>
                        <IconButton variant="plain" sx={{ ml: 'auto' }} onClick={() => setModalChangePsw(true)}>
                            <Key />
                        </IconButton>
                        <IconButton variant="plain" sx={{ ml: 'auto' }} onClick={() => signout()}>
                            <Logout />
                        </IconButton>
                    </Stack>
                </Box>
            </Sheet>

            {/* MODAL CHANGE PASSWORD */}
            <Modal open={modalChangePsw} onClose={() => {
                setModalChangePsw(false)
            }}>
                <ModalDialog>
                    <DialogTitle>Cambia password</DialogTitle>
                    <DialogContent>Completa i campi per cambiare la tua password.</DialogContent>
                    {
                        tab === 0 ?
                            <>
                                <FormControl>
                                    <FormLabel>Email</FormLabel>
                                    <Input type="email"
                                           value={firebase.auth().currentUser?.email.toString()}
                                           disabled
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Password attuale</FormLabel>
                                    <Input type="password"
                                           value={oldPassword}
                                           onChange={e => setOldPassword(e.target.value)}
                                    />
                                </FormControl>
                                <Button
                                    disabled={oldPassword.length <= 6 || loading}
                                    onClick={() => {
                                        firebase.auth().signInWithEmailAndPassword(firebase.auth().currentUser.email, oldPassword)
                                            .then(() => {
                                                setLoading(false)
                                                setTab(1)
                                            })
                                            .catch((e) => {
                                                console.log(e)
                                                setLoading(false)
                                            })
                                    }}
                                >
                                    Avanti
                                </Button>
                            </>
                            :
                            tab === 1 ?
                                <>
                                    <FormControl>
                                        <FormLabel>Nuova password</FormLabel>
                                        <Input type="password"
                                               value={password1}
                                               onChange={e => setPassword1(e.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Ripeti password</FormLabel>
                                        <Input type="password"
                                               value={password2}
                                               onChange={e => setPassword2(e.target.value)}
                                        />
                                    </FormControl>
                                    <Button
                                        disabled={password1.length <= 6 || password1 !== password2 || loading}
                                        onClick={() => {
                                            firebase.auth().currentUser.updatePassword(password1)
                                                .then(() => {
                                                    setPassword1("")
                                                    setPassword2("")
                                                    setModalChangePsw(false)
                                                    setLoading(false)
                                                    window.alert("Password cambiata con successo")
                                                })
                                                .catch((e) => {
                                                    console.log(e)
                                                    setLoading(false)
                                                })
                                        }}
                                    >
                                        Conferma
                                    </Button>
                                </>
                                : null
                    }
                </ModalDialog>
            </Modal>
        </>
    )

    function signout() {
        if(window.confirm("Effettuare il logout?")) {
            firebase.auth().signOut().then(() => {
                window.location.href = "/login"
            })
        }
    }

    function ItemMenu({label, icon, link, chip}) {

        const active = pathname===link

        return (
            <Link to={link} style={{width: "100%"}} target={link.includes("https://") || link.includes("http://") ? "_blank" : ""}>
                <Button
                    selected={active}
                    variant={active ? "soft" : "plain"}
                    onClick={() => closeSidebar()}
                    color={active ? "primary" : "neutral"}
                    fullWidth
                >
                    <Stack direction="row" spacing={1} sx={{width: "100%", justifyContent:"start", alignItems:"center"}}>
                        <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>{icon}</Box>
                        <Box sx={{
                            display: {
                                sx:"block",
                                lg:"none",
                            }
                        }}>{label}</Box>
                    </Stack>
                </Button>
            </Link>
        )
    }
}

export const openSidebar = () => {
    if (typeof document !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
};

export const closeSidebar = () => {
    if (typeof document !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
};