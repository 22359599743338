import {GlobalStyles, IconButton, Sheet} from "@mui/joy";
import {Menu} from "@mui/icons-material";
import {closeSidebar, openSidebar} from "./Sidebar";


export function Header() {

    return (
        <Sheet
            sx={{
                display: { xs: 'flex', lg: 'none' },
                alignItems: 'center',
                position: 'fixed',
                top: 0,
                width: '100vw',
                height: 'var(--Header-height)',
                zIndex: 9995,
                py: 1,
                px: 2,
                gap: 1,
                boxShadow: 'sm',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Header-height': '52px',
                        [theme.breakpoints.up('lg')]: {
                            '--Header-height': '0px',
                        },
                    },
                })}
            />
            <IconButton
                onClick={() => toggleSidebar()}
                variant="outlined"
                color="neutral"
                size="sm"
            >
                <Menu />
            </IconButton>
            {
                //  <MuiLogo variant="plain" sx={{ boxShadow: 'none', mr: 'auto' }} />
            }
        </Sheet>
    )
}

export const toggleSidebar = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--SideNavigation-slideIn');
        if (slideIn) {
            closeSidebar();
        } else {
            openSidebar();
        }
    }
};