import {Sidebar} from "../utils/Sidebar";
import {
    Box, Button,
    Checkbox, Chip,
    DialogContent,
    DialogTitle,
    FormControl, FormLabel,
    Grid,
    IconButton, Input,
    Modal,
    ModalDialog, Option, Select,
    Sheet,
    Stack, Typography
} from "@mui/joy";
import {Header} from "../utils/Header";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'moment/locale/it'
import {useState} from "react";
import {
    COLOR_ANDREA,
    COLOR_ANDREA_PENDING,
    COLOR_MIRKO,
    COLOR_MIRKO_PENDING,
    COLOR_PENDING,
    DB_PRENOTAZIONI,
    DB_TURNI,
    getHolidays,
    isValidEmail,
    isValidPhoneNumber,
    STATUS_CONFIRMED,
    STATUS_DELETED,
    STATUS_PENDING,
    STATUS_REJECTED,
    UUID
} from "../utils/General";
import {Add} from "@mui/icons-material";
import firebase from "firebase/compat/app";
import {nanoid} from "nanoid";


export function Turni({data, prenotazioni}) {

    const [filterAndrea, setFilterAndrea] = useState(true)
    const [filterMirko, setFilterMirko] = useState(true)
    const [loading, setLoading] = useState(false)
    const [modalAdd, setModalAdd] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const turnoInitialData = {
        barber: "",
        date: "",
        timestart: "",
        timeend: ""
    }
    const [newTurno, setNewTurno] = useState(turnoInitialData)

    const localizer = momentLocalizer(moment)
    const events = data.map(a => {
        const s = moment.unix(a.datetimestart)
        const e = moment.unix(a.datetimeend)
        if((a.barber==="Andrea" && filterAndrea) || (a.barber==="Mirko" && filterMirko)) {
            return {
                ...a,
                start: s.toDate(),
                end: e.toDate(),
                title: a.barber,
                date: s.format("YYYY-MM-DD"),
                timestart: s.format("HH:mm"),
                timeend: e.format("HH:mm"),
            }
        }
    })
        .sort((a, b) => a.barber.localeCompare(b.barber))
        .concat(getHolidays().map(h => {
            const start = moment(h + "-" + moment().format("YYYY"), "MM-DD-YYYY").startOf("day")
            const end = start.clone().endOf("day")
            return {start: start.toDate(), end: end.toDate(), title: "Festività"}
        }))

    const eventStyleGetter = (event, start, end, isSelected) => {
        let backgroundColor
        switch (event.barber) {
            case "Andrea" :
                backgroundColor = COLOR_ANDREA
                break
            case "Mirko" :
                backgroundColor = COLOR_MIRKO
                break
            default :
                backgroundColor = COLOR_PENDING
                break
        }
        let style = {
            backgroundColor: backgroundColor,
            borderRadius: '5px',
            opacity: 1,
            color: 'black',
            border: '0px',
            display: 'block',
            boxShadow: "0 0 10px rgba(0,0,0,0.2)",
        };
        return {
            style: style
        };
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: "row",
            justifyContent: "start",
            minHeight: "100vh",
            minWidth: "100%",
            maxWidth: "100%"
        }}>
            <Header/>
            <Sidebar/>
            <Box sx={{
                width: {xs:"100vw", lg:"calc(100vw - 175px)"},
                height: "calc(100vh - 4em)",
                ml: {xs:"0", lg:"100px"},
                mt: {xs:"60px", lg:0},
            }}>
                <Stack direction="row" sx={{justifyContent:"space-between", alignItems:"center", width:"100%", px:3, pt:3}}>
                    <Typography level="h2">Turni</Typography>
                    <Stack direction="row" sx={{justifyContent:"end"}} spacing={2}>
                        <Checkbox label="Andrea" size="sm"
                                  checked={filterAndrea}
                                  onClick={() => setFilterAndrea(!filterAndrea)}
                        />
                        <Checkbox label="Mirko" size="sm"
                                  checked={filterMirko}
                                  onClick={() => setFilterMirko(!filterMirko)}
                        />
                    </Stack>
                </Stack>
                <Box sx={{width: "100%", height: "100%", p: 3}}>
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        defaultView="week"
                        eventPropGetter={(eventStyleGetter)}
                        selectable={true}
                        min={new Date(2020, 1, 0, 6, 0, 0)} max={new Date(2020, 1, 0, 21, 0, 0)}
                        onSelectEvent={(e) => {
                            if(e.title !== "Festività") setSelectedEvent(e)
                        }}
                        onSelectSlot={(e) => {
                            const date = moment(e.start).format("YYYY-MM-DD")
                            const time = moment(e.start).format("HH:mm")
                            setNewTurno({
                                ...newTurno,
                                date: date,
                                timestart: time
                            })
                            setModalAdd(true)
                        }}
                    />
                    <IconButton variant="solid" color="primary"
                                sx={{position: "fixed", bottom: 0, right: 0, zIndex: 10, m: 3, borderRadius: 50}}
                                onClick={() => {
                                    setModalAdd(true)
                                }}
                    >
                        <Add/>
                    </IconButton>
                </Box>
            </Box>

            {/* MODAL ADD */}
            <Modal open={modalAdd} onClose={() => {
                setModalAdd(false)
                setNewTurno(turnoInitialData)
            }}>
                <ModalDialog>
                    <DialogTitle>Nuovo turno</DialogTitle>
                    <DialogContent>Completa i campi per inserire un nuovo turno.</DialogContent>
                    <Grid container spacing={2}>
                        <Grid md={6}>
                            <FormControl>
                                <FormLabel>Barbiere</FormLabel>
                                <Select placeholder="Scegli…"
                                        value={newTurno.barber}
                                        onChange={(e, newValue) => setNewTurno({
                                            ...newTurno,
                                            barber: newValue
                                        })}
                                >
                                    <Option value="Andrea">Andrea</Option>
                                    <Option value="Mirko">Mirko</Option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid md={6}>
                            <FormControl>
                                <FormLabel>Data turno</FormLabel>
                                <Input type="date"
                                       value={newTurno.date}
                                       onChange={e => setNewTurno({...newTurno, date: e.target.value})}
                                       error={moment(newTurno.date + " 23:59", "YYYY-MM-DD HH:mm").isBefore(moment())}
                                />
                            </FormControl>
                        </Grid>
                        <Grid md={6}>
                            <FormControl>
                                <FormLabel>Ora inizio turno</FormLabel>
                                <Input type="time"
                                       value={newTurno.timestart}
                                       onChange={e => setNewTurno({...newTurno, timestart: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid md={6}>
                            <FormControl>
                                <FormLabel>Ora fine turno</FormLabel>
                                <Input type="time"
                                       value={newTurno.timeend}
                                       onChange={e => setNewTurno({...newTurno, timeend: e.target.value})}
                                       error={moment(newTurno.timeend, "HH:mm").isBefore(moment(newTurno.timestart, "HH:mm")) || newTurno.timeend===""}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Button
                        disabled={newTurno.barber === "" || newTurno.date === "" || newTurno.timestart === "" || newTurno.timeend === "" || loading}
                        onClick={() => addTurno().then(() => {
                            setModalAdd(false)
                            setLoading(false)
                            setNewTurno(turnoInitialData)
                        })}
                    >
                        Aggiungi
                    </Button>
                </ModalDialog>
            </Modal>

            {/* MODAL SELECTED EVENT */}
            <Modal open={Boolean(selectedEvent)} onClose={() => setSelectedEvent(null)}>
                <ModalDialog>
                    {
                        selectedEvent !== null ?
                            <>
                                <DialogTitle>
                                    {selectedEvent.barber}
                                </DialogTitle>
                                <DialogContent>{moment(selectedEvent.start).format("DD/MM/YYYY HH:mm")} - {moment(selectedEvent.end).format("HH:mm")}</DialogContent>
                                <Grid container spacing={2}>
                                    <Grid md={6}>
                                        <FormControl>
                                            <FormLabel>Barbiere</FormLabel>
                                            <Select placeholder="Scegli…"
                                                    value={selectedEvent.barber}
                                                    onChange={(e, newValue) => setSelectedEvent({
                                                        ...selectedEvent,
                                                        barber: newValue
                                                    })}
                                            >
                                                <Option value="Andrea">Andrea</Option>
                                                <Option value="Mirko">Mirko</Option>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid md={6}>
                                        <FormControl>
                                            <FormLabel>Data turno</FormLabel>
                                            <Input type="date"
                                                   value={selectedEvent.date}
                                                   onChange={e => setSelectedEvent({
                                                       ...selectedEvent,
                                                       date: e.target.value
                                                   })}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid md={6}>
                                        <FormControl>
                                            <FormLabel>Ora inizio turno</FormLabel>
                                            <Input type="time"
                                                   value={selectedEvent.timestart}
                                                   onChange={e => setSelectedEvent({
                                                       ...selectedEvent,
                                                       timestart: e.target.value
                                                   })}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid md={6}>
                                        <FormControl>
                                            <FormLabel>Ora fine turno</FormLabel>
                                            <Input type="time"
                                                   value={selectedEvent.timeend}
                                                   onChange={e => setSelectedEvent({
                                                       ...selectedEvent,
                                                       timeend: e.target.value
                                                   })}
                                                   error={moment(selectedEvent.timeend, "HH:mm").isBefore(moment(selectedEvent.timestart, "HH:mm")) || selectedEvent.timeend===""}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid md={12}>
                                        <Stack direction="row" spacing={2}>
                                            <Button variant="outlined" color="danger" fullWidth
                                                    onClick={() => {

                                                        if(moment(selectedEvent.date).isBefore(moment())) {
                                                            alert("Non puoi eliminare un turno passato")
                                                        } else {
                                                            const prens = prenotazioni.filter(p => {
                                                                const startpren = moment.unix(p.datetimestart)
                                                                const endpren = moment.unix(p.datetimeend)
                                                                const startturno = moment.unix(selectedEvent.datetimestart)
                                                                const endturno = moment.unix(selectedEvent.datetimeend)
                                                                return (moment(startpren).isBetween(startturno, endturno, undefined, "[]") || moment(endpren).isBetween(startturno, endturno, undefined, "[]")) && p.barber===selectedEvent.barber;
                                                            })

                                                            if (window.confirm(`Eliminare definitivamente il turno?\nCi sono ${prens.length} prenotazioni durante questo turno. Se elimini il turno verranno annullate anche le prenotazioni`)) {
                                                                deleteTurno(prens).then(() => {
                                                                    setSelectedEvent(null)
                                                                    setLoading(false)
                                                                })
                                                            }
                                                        }
                                                    }}
                                            >
                                                Elimina turno
                                            </Button>
                                            <Button fullWidth
                                                    disabled={selectedEvent.barber === "" || selectedEvent.date === "" || selectedEvent.timestart === "" || selectedEvent.timeend === "" || loading}
                                                    onClick={() => updateTurno().then(() => {
                                                        setSelectedEvent(null)
                                                        setLoading(false)
                                                    })}
                                            >
                                                Modifica
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </>
                            : null
                    }
                </ModalDialog>
            </Modal>

        </Box>
    )

    async function addTurno() {
        setLoading(true)

        const id = nanoid()
        const datetimestart = moment(String(newTurno.date + " " + newTurno.timestart), "YYYY-MM-DD HH:mm")
        const datetimeend = moment(String(newTurno.date + " " + newTurno.timeend), "YYYY-MM-DD HH:mm")

        await firebase.firestore().collection(DB_TURNI).doc(id).set({
            id: id,
            barber: newTurno.barber,
            datetimestart: datetimestart.unix(),
            datetimeend: datetimeend.unix(),
        })
    }

    async function updateTurno() {
        setLoading(true)

        const datetimestart = moment(String(selectedEvent.date + " " + selectedEvent.timestart), "YYYY-MM-DD HH:mm")
        const datetimeend = moment(String(selectedEvent.date + " " + selectedEvent.timeend), "YYYY-MM-DD HH:mm")

        await firebase.firestore().collection(DB_TURNI).doc(selectedEvent.id).update({
            barber: selectedEvent.barber,
            datetimestart: datetimestart.unix(),
            datetimeend: datetimeend.unix(),
        })
    }

    async function deleteTurno(prens) {
        setLoading(true)

        await firebase.firestore().collection(DB_TURNI).doc(selectedEvent.id).delete()
        for (const p of prens) {
            await firebase.firestore().collection(DB_PRENOTAZIONI).doc(p.id).update({
                status: STATUS_DELETED
            })
        }
    }
}