import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "./AuthProvider";
import {Login} from "../pages/Login";
import {Home} from "../pages/Home";
import {Prenotazioni} from "../pages/Prenotazioni";
import {Turni} from "../pages/Turni";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore"
import {DB_PRENOTAZIONI, DB_TURNI, STATUS_CONFIRMED, STATUS_DELETED, STATUS_PENDING} from "./General";
import {Statistiche} from "../pages/Statistiche";

export function Router() {

    const {currentUser} = useContext(AuthContext)

    const [prenotazioni, setPrenotazioni] = useState([])
    const [turni, setTurni] = useState([])
    useEffect(() => {
        if(currentUser) {
            firebase.firestore().collection(DB_PRENOTAZIONI)
                .where("status", "in", [STATUS_CONFIRMED, STATUS_PENDING])
                .orderBy("datetimestart", "desc")
                .limit(1000)
                .onSnapshot((querySnapshot) => {
                    let res = [];
                    querySnapshot.forEach((doc) => {
                        res.push(doc.data());
                    });
                    setPrenotazioni(res)
                });

            firebase.firestore().collection(DB_TURNI)
                .onSnapshot((querySnapshot) => {
                    let res = [];
                    querySnapshot.forEach((doc) => {
                        res.push(doc.data());
                    });
                    setTurni(res)
                });
        }
    }, [currentUser])

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={!currentUser ? <Navigate to="/login" /> : <Home prenotazioni={prenotazioni} />} />
                    <Route exact path="/login" element={currentUser ? <Navigate to="/" /> : <Login />} />
                    <Route exact path="/prenotazioni" element={<Prenotazioni data={prenotazioni} />} />
                    <Route exact path="/turni" element={<Turni data={turni} prenotazioni={prenotazioni} />} />
                    <Route exact path="/statistiche" element={<Statistiche />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}